import React from 'react';
import Head from 'next/head'
function SEO({ initialSeo }: any) {

  return (
    <>
    <Head>
        {initialSeo?.seo?.title && <title>{initialSeo?.seo?.title}</title>}
        {initialSeo?.seo?.description && (
          <meta name="description" content={initialSeo?.seo?.description} />
        )}
        {initialSeo?.seo?.focusKeywords && (
          <meta name="keywords" content={initialSeo?.seo?.focusKeywords} />
        )}
        {initialSeo?.seo?.robots && (
          <meta name="robots" content={initialSeo?.seo?.robots} />
        )}
        {initialSeo?.seo?.canonicalUrl && (
          <link rel="canonical" href={initialSeo?.seo?.canonicalUrl} />
        )}
        {initialSeo?.seo?.openGraph?.locale && (
          <meta property="og:locale" content={initialSeo?.seo?.openGraph?.locale} />
        )}
        {initialSeo?.seo?.openGraph?.type && (
          <meta property="og:type" content={initialSeo?.seo?.openGraph?.type} />
        )}
        {initialSeo?.seo?.openGraph?.title && (
            <meta property="og:title" content={initialSeo?.seo?.openGraph?.title} />
        )}
        {initialSeo?.seo?.openGraph?.description && (
            <meta property="og:description" content={initialSeo?.seo?.openGraph?.description} />
        )}`
        {initialSeo?.seo?.openGraph?.url && (
            <meta property="og:url" content={initialSeo?.seo?.openGraph?.url} />
        )}
        {initialSeo?.seo?.openGraph?.siteName && (
            <meta property="og:site_name" content={initialSeo?.seo?.openGraph?.siteName} />
        )}
         {initialSeo?.seo?.openGraph?.updatedTime && (
            <meta property="og:updated_time" content={initialSeo?.seo?.openGraph?.updatedTime} />
         )}
         {initialSeo?.seo?.openGraph?.articleMeta?.publishedTime && (
            <meta property="article:published_time" content={initialSeo?.seo?.openGraph?.articleMeta?.publishedTime} />
         )}
         {initialSeo?.seo?.openGraph?.articleMeta?.modifiedTime && (
            <meta property="article:modified_time" content={initialSeo?.seo?.openGraph?.articleMeta?.modifiedTime} />
         )}
        {initialSeo?.seo?.openGraph?.twitterMeta?.card && (
            <meta name="twitter:card" content={initialSeo?.seo?.openGraph?.twitterMeta?.card} />
        )}
        {initialSeo?.seo?.openGraph?.twitterMeta?.title && (
            <meta name="twitter:title" content={initialSeo?.seo?.openGraph?.twitterMeta?.title} />
        )}
        {initialSeo?.seo?.openGraph?.twitterMeta?.description && (
            <meta name="twitter:description" content={initialSeo?.seo?.openGraph?.twitterMeta?.description} />
        )}
        {initialSeo?.seo?.openGraph?.twitterMeta?.image && (
            <meta name="twitter:image" content={initialSeo?.seo?.openGraph?.twitterMeta?.image} />
        )}
    </Head>
    {/* {initialSeo?.seo?.jsonLd?.raw && (
        <div dangerouslySetInnerHTML={{ __html: initialSeo?.seo?.jsonLd?.raw }}></div>
    )} */}
    </>
  );
}

export default SEO;